import Link from 'next/link';
import LoginForm from '../components/forms/LoginForm';
import Layout from 'components/home/Layout';

const LoginPage: React.FC = () => (
  <Layout>
    <div className="flex min-h-screen flex-col">
      <div className="mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="text-center">
          <div className="mt-6 text-center text-2xl text-strong-950">Log in</div>
        </div>
        <div className="mt-8 rounded-lg px-4 py-8 shadow-lg ring-1 ring-soft-200 sm:px-10">
          <LoginForm />
        </div>
      </div>
    </div>
  </Layout>
);

export default LoginPage;
